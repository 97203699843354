$(function(){
    $('.share', this).bind('click', function(e) {
        e.preventDefault();
        e.stopPropagation();

        var loc = location.href;
        var action = $(this).attr('data-action');

        if( action == 'twitter' )
        {
            var title  = $(this).attr('title');

            window.open('http://twitter.com/share?url=' + loc + '&text=' + title + ' - ' + loc + ' - via @twitter', 'twitterwindow', 'height=255, width=550, top='+($(window).height()/2 - 225) +', left='+($(window).width()/2 - 275 ) +', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
        }
        else if( action == 'facebook' )
        {
            var t = document.title;

            window.open('http://www.facebook.com/sharer.php?u='+encodeURIComponent(loc)+'&t='+encodeURIComponent(t),'sharer','status=0,width=626,height=436, top='+($(window).height()/2 - 225) +', left='+($(window).width()/2 - 313 ) +', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
        }
        else if( action == 'google' )
        {
            window.open('https://plus.google.com/share?url='+encodeURIComponent(loc),'Google Share','status=0,width=626,height=436, top='+($(window).height()/2 - 225) +', left='+($(window).width()/2 - 313 ) +', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
        }
        else if( action == 'pinterest' )
        {
            //upravit
            window.open('http://www.pinterest.com/pin/create/button/?url='+encodeURIComponent(loc),'Pinterest PinIt','status=0,width=626,height=436, top='+($(window).height()/2 - 225) +', left='+($(window).width()/2 - 313 ) +', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
        }
    });

});
